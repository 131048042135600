import Cookies from "universal-cookie";

const cookies = new Cookies();
const defaultCookieOptions = { path: "/", maxAge: 604800 };

export const getCookie = (name) => {
    return cookies.get(name);
}

export const setCookie = (name, value, options = defaultCookieOptions) => {
    cookies.set(name, value, options);
    return value;
}

export const removeCookie = (name) => {
    cookies.remove(name, { path: "/" });
    return true;
}