import axios from "axios";
import { getCookie, setCookie } from "../utils/cookieActions";


export const userAuth = async () => {
    return axios.get("/check-token").then(({ data }) => {
        if (data !== 1) {
          window.location.href = '/login'
          return;
        }
        axios.get("/check-role").then(({ data }) => {
          const cookieRole = getCookie("b2b_role_admin");
          if (cookieRole !== data) {
            setCookie("b2b_role_admin", data)
          }
        }).catch((err) => {
          if (err.response.status === 401) {
            return err;
          }
        })
  
      }).catch((err) => {
        if (err.response.status === 401) {
          return err;
        }
      })
}