import axios from "axios"
import { getCookie, setCookie } from "../utils/cookieActions";
import { userAuth } from "./api";
const checkAuth = () => {
  const TOKEN = getCookie("b2b_token_admin")
  const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"]

  const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r))

  if (!TOKEN && !isPublicPage) {
    window.location.href = '/login'
    return null;
  } else {
    axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`;
    axios.defaults.baseURL = `https://api.b2task.com/api`;

    axios.interceptors.request.use(function (config) {
      document.body.classList.add('loading-indicator');
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      setTimeout(() => {
        document.body.classList.remove('loading-indicator');
      }, 200);
      return response;
    }, function (error) {
      setTimeout(() => {
        document.body.classList.remove('loading-indicator');
      }, 200);
      return Promise.reject(error);
    });

    userAuth()

    return TOKEN
  }
}

export default checkAuth